// JavaScript skyren
document.addEventListener('touchstart', function () {
    return false;
}, true);


var pubWidth = $(window).width();


// 是否引入了pageloader.js
var isPageLoader = false; //public.js true时使用元素动画
var isCount = false; //public.js true时使用数字首屏滚动
var isScrollMove = false; //pageloader.js是否执行元素动画
var isLoadCount = false; //pageloader.js是否执行数字首屏滚动

// 是否在首屏滚动数字
function loadCount() {
    $(window).scroll();
};
!isCount || loadCount();

// 是否执行动画
var scrollMove = function (arg) {
    var arg = arg || {};
    var options = {
        moreCell: arg.moreCell || ".scroll-move", //移动对象
        onClassName: arg.onClassName || "move-loaded", //动画对象
        percent: arg.percent || 0.9 //显示位置
    };
    let winHeight = $(window).height(),
        scrollTop = 0,
        offsetTop = 0;

    function scrollBox() {
        scrollTop = $(window).scrollTop();
        $(options.moreCell).each(function (i) {
            offsetTop = $(this).offset().top;
            if ((winHeight + scrollTop) * options.percent > offsetTop) {
                $(this).addClass(options.onClassName);
            }
        });
    };
    scrollBox();
    $(window).scroll(function () {
        scrollBox();
    });
}
!isPageLoader || scrollMove();

var userWidth = $(window).width();
var userHeight = $(window).height();




function slideHeigth(userClass, istrue) {
    var userWidth1 = $(window).width();
    var userHeight1 = $(window).height();
    var _istrue;
    if (istrue == undefined) {
        _istrue = true;
    } else {
        _istrue = false;
    }
    if (userWidth1 > 750) {
        $(userClass).height(userHeight1 - 142);
        if (_istrue) {
            $(userClass).find(".ban_bd li").height(userHeight1 - 142);
        }
    } else {
        $(userClass).height("auto");
        if (_istrue) {
            $(userClass).find(".ban_bd li").height("auto");
        }
    }
}


if (userWidth <= 750) {
    var crumbsWidth = 0;
    var tabWidth = 0;
    var senScrollW = 0;
    var secondW = 0;
    var fourthW = 0;
    //  crumbsSize = $(".crumbs-tabs a").length;
    $(".crumbs-box a").each(function (i) {
        crumbsWidth += $(this).outerWidth(true);
    });
    $(".jsTab li").each(function (i) {
        tabWidth += $(this).outerWidth(true);
    });
    $(".sen-box li").each(function(){
        senScrollW += $(this).outerWidth(true);
    });
    // $(".crumbs-box").width(crumbsWidth);
    // $(".jsTab ul").width(tabWidth);
    $(".sen-box ul").width(senScrollW);
    $(".second-box a").each(function(i){
        secondW+=$(this).outerWidth(true);
    });
    $(".fourth-scroll li").each(function(i){
        fourthW+=$(this).outerWidth(true);
    });
    $(".second-box").width(secondW);
    $(".fourth-scroll").width(fourthW);
}
$(".details-btn").click(function () {
    if (!$(this).hasClass("z-active")) {

        $(".details-p").height("auto");
        $(this).addClass("z-active").html('收起更多<img src="images/icon9.png" alt="">');
    } else {
        $(".details-p").height(150);
        $(this).removeClass("z-active").html('查看更多<img src="images/icon8.png" alt="">');
    }
})

$(".about-btn").click(function () {
    if (!$(".m-user-alert").hasClass("z-active")) {
        $(".m-user-alert").addClass("z-active");
    } else {
        $(".m-user-alert").removeClass("z-active");
    }
})
$(".alert-back").click(function () {
    $(".m-user-alert").removeClass("z-active");
})
$(function () {
    $(".top-click").click(function () {
        $("html,body").scrollTop(0);
        $.fn.fullpage.moveTo(1, 0);
    })
});


//锚点
// $(".jsLinksFixed").html($(".jsLinks").html());
$(".jsLinks li").on('click', function () {
    var linkId = $(this).data("link");
    var linkTop = $("#" + linkId).offset().top - 150;
    $(".jsLinks li").removeClass("z-active");
    $(this).addClass("z-active");
    $("html,body").animate({ scrollTop: linkTop }, 300);

    // $(".jsLinksFixed li").removeClass("z-active");
    // $(".jsLinksFixed li").eq($(this).index()).addClass("z-active");
});

function linksAll() {
    var thisHeight = $(window).scrollTop() + $(window).height() * 0.8;
    $(".jsLinks li").each(function (i) {
        var a = i + 1;
        if ($("#link" + a).offset().top < thisHeight) {
            $(".jsLinks li").removeClass("z-active");
            $(".jsLinks li").eq(i).addClass("z-active");
        }
    });
}
var clock; //函数节流
function userScroll() {
    $(window).scroll(function () {
        if (clock) {
            clearTimeout(clock);
        }
        clock = setTimeout(function () {
            linksAll();
        }, 0)
    });
}
userScroll();






$(".jsLinksFixed li").on('click', function () {
    var linkId = $(this).data("link");
    var linkTop = $("#" + linkId).offset().top - 100;
    $(".jsLinksFixed li").removeClass("z-active");
    $(this).addClass("z-active");
    $("html,body").animate({ scrollTop: linkTop }, 300);

    // $(".jsLinks li").removeClass("z-active");
    // $(".jsLinks li").eq($(this).index()).addClass("z-active");
});
var footWindth = $(window).width();
if (footWindth < 750) {
    $(".jsFoot .title").on('click', function () {
        $(".jsFoot").find(".foot-box").removeClass("z-open");
        $(this).parents(".jsFoot").find(".foot-box").addClass("z-open");
    });
}
if (footWindth > 750) {
    $(".jsNav li").hover(function () {
        if ($(this).find(".nav-subs").size() > 0) {
            $(this).addClass('z-current');
            // $(".g-nav-bg").addClass('z-current');
        }
    }, function () {
        $(".jsNav li").removeClass('z-current');
        // $(".g-nav-bg").removeClass('z-current');
    });
    // $(".jsSearch").on('click', function () {
    //     if (!$(".g-search-bg").hasClass("z-current")) {
    //         $(".g-search-bg").addClass('z-current');
    //     } else {
    //         $(".g-search-bg").removeClass('z-current');
    //     }
    //     return false;
    // });
    // $(".g-search-bg").on('click', function () {
    //     return false;
    // })
    // $("body").not(".g-search-bg").on('click', function (e) {
    //     $(".g-search-bg").removeClass('z-current');
    // });
} else {
    $(".jsMenu").on('click', function () {
        if (!$(".jsMenu").hasClass("z-current")) {
            $("html,body").addClass("z-index");
            $(".jsNav").addClass("z-nav-current");
            $(".jsMenu").addClass("z-current").find("i").attr("class", "iconfont icon-close");
            $(".head-en").addClass("z-current");
        } else {
            $("html,body").removeClass("z-index");
            $(".jsNav").removeClass("z-nav-current");
            $(".jsMenu").removeClass("z-current").find("i").attr("class", "iconfont icon-menu");
            $(".head-en").removeClass("z-current");
        }
    });
    $(".jsNav li i").on('click', function () {
        if (!$(this).parents("li").hasClass("z-current")) {
            $(".jsNav li").removeClass("z-current");
            $(".jsNav li").find(".iconfont").removeClass("icon-up").addClass("icon-down");
            $(this).parents("li").addClass("z-current");
            $(this).removeClass("icon-down").addClass("icon-up");
            return false;
        } else {
            $(this).parents("li").removeClass("z-current");
            $(this).removeClass("icon-up").addClass("icon-down");
            return false;
        }
    })
}
//标签切换
$(".jsTab li").not($(".notab")).on('click', function () {
    var thisTab = $(this).parents(".jsTab");
    var tabSub = thisTab.parents(".jsTabs").find(".u-tab-content").find(".tab-sub");
    var thisIndex = $(this).index();
    thisTab.find("li").removeClass('z-current');
    $(this).addClass('z-current');
    tabSub.addClass('hidden');
    tabSub.eq(thisIndex).removeClass('hidden');
    if (thisTab.parents(".jsTabs").find(".u-tab-content-other").length > 0) {
        var tabSubOther = thisTab.parents(".jsTabs").find(".u-tab-content-other").find(".tab-sub");
        tabSubOther.addClass('hidden');
        tabSubOther.eq(thisIndex).removeClass('hidden');
    }
});
if (footWindth <= 750) {
    $(".jsScroll ul").width($(".jsScroll li").length * 165 - 15);
    $(".jsScrollService ul").width($(".jsScrollService li").length * 180);
    $(".jsScrollAbout ul").width($(".jsScrollAbout li").length * 100);
    $(".bus-dl dl").each(function (i) {
        $(this).width($(this).find("dd").length * 216);
    })

}
$(".jsBusClick li .title").on('click', function () {
    if (!$(this).parent().hasClass("z-current")) {
        $(".jsBusClick li").removeClass("z-current");
        $(".jsBusClick li").find(".title .iconfont").removeClass("iconminus-sign").addClass("iconadd");
        $(this).parent().addClass("z-current");
        $(this).parent().find(".title .iconfont").removeClass("iconadd").addClass("iconminus-sign");
    } else {
        $(this).parent().removeClass("z-current");
        $(this).parent().find(".title .iconfont").removeClass("iconminus-sign").addClass("iconadd");
    }
});
$(".jsBusClick li").eq(0).find(".title").click();

// 加载更多
var loadMore = function (arg) {
    var arg = arg || {};
    var options = {
        moreCell: arg.moreCell || ".m-joinus", //主元素对象
        moreCellchlid: arg.moreCellchlid || "ul", //子元素对象
        trigger: arg.trigger || "click", //触发事件
        loadNum: arg.loadNum || 3, //页数
        triggertime: arg.triggertime || 1000, //loading事件
        callFun: arg.callFun || loadAjax, //回调函数
        clickbtn: arg.clickbtn || ".view-btn", //触发按钮class
        loadClass: arg.loadClass || ".loaded", //loading按钮class
        hiddenClass: arg.hiddenClass || "z-hidden", //隐藏class
        loadtext: arg.loadtext || "没有更多了！" //加载完文字
    };
    var elementObj = {
        eleUl: $(options.moreCell).find(options.moreCellchlid),
        eleBtn: $(options.moreCell).find(options.clickbtn),
        eleLoad: $(options.moreCell).find(options.loadClass),
        eleClass: options.hiddenClass,
        isLoad: true,
        winHeight: $(window).height(),
        eleNum: 0
    };
    var loadFun = function () {
        if (elementObj.isLoad) {
            elementObj.isLoad = false;

            if (elementObj.eleNum < options.loadNum) {
                elementObj.eleNum++;
                options.trigger == "click" && elementObj.eleBtn.addClass(elementObj.eleClass);
                elementObj.eleLoad.removeClass(elementObj.eleClass);
                setTimeout(function () {
                    elementObj.eleUl.append(options.callFun());
                    options.trigger == "click" && elementObj.eleBtn.removeClass(elementObj.eleClass);
                    elementObj.eleLoad.addClass(elementObj.eleClass);
                    elementObj.isLoad = true;
                    if (elementObj.eleNum >= options.loadNum) {
                        options.trigger == "click" && elementObj.eleBtn.addClass(elementObj.eleClass);
                        elementObj.eleLoad.removeClass(elementObj.eleClass).html(options.loadtext);
                        elementObj.isLoad = false;
                    }
                }, 1000);
            } else {
                options.trigger == "click" && elementObj.eleBtn.addClass(elementObj.eleClass);
                elementObj.eleLoad.removeClass(elementObj.eleClass).html(options.loadtext);
                options.trigger != "click" && $(window).unbind('scroll');
            }
        }
    }
    if (options.trigger == "click") {
        elementObj.eleBtn.on('click', function () {
            loadFun();
        });
    }
    if (options.trigger == "scroll") {
        elementObj.eleBtn.addClass(elementObj.eleClass);
        $(window).scroll(function () {
            var scroll_height = $(window).scrollTop();
            var m_height = $("body").outerHeight();
            if (elementObj.winHeight + scroll_height > m_height - 100) {
                loadFun();
            }
        });
        $(window).scroll();
    }
};

$(".m-text .u-button").on('click', function () {
    if (footWindth <= 750) {
        $(this).parents(".m-text").addClass("text-box-open");
    }
});
$(".train-btn").on('click', function () {
    if (footWindth <= 750) {
        $(this).parents(".train-tabs-text").addClass("text-box-open");
    }
});


var tabScroll = function (_thisTag) {
    var selfNum = 0;
    var selfz = 4;
    var slideWidth = $(window).width();

    var selfWidth = $(_thisTag).find(".slide-sub-box li").width();
    var selfNums = $(_thisTag).find(".slide-sub-box li").length;
    $(_thisTag).find(".slide-sub-box ul").width(selfWidth * selfNums);

    if (userWidth > 750) {
        $(_thisTag).find(".slide-sub-box li").on('click', function () {
            var _this = $(this);
            var self = $(this).index();
            selfNum = self;
            $(_thisTag).find(".slide-sub-box li").removeClass("z-current");
            _this.addClass("z-current");

            $(_thisTag).find(".about-cont-sub").addClass("hidden");
            $(_thisTag).find(".about-cont-sub").eq(self).removeClass("hidden");

            $(_thisTag).find(".slide-bg").css("left", selfWidth * (selfNum));
            $(_thisTag).find(".slide-sub-box .slide-all").animate({
                scrollLeft: selfWidth * (selfNum - selfz)
            }, 300);
        })
    } else {
        $(_thisTag).find(".slide-sub-box li").on('click', function () {
            var _this = $(this);
            var self = $(this).index();
            selfNum = self;
            $(_thisTag).find(".slide-sub-box li").removeClass("z-current");
            _this.addClass("z-current");

            $(_thisTag).find(".about-cont-sub").addClass("hidden");
            $(_thisTag).find(".about-cont-sub").eq(self).removeClass("hidden");
        })
    }


    $(_thisTag).find(".slide-left").on('click', function () {
        if (selfNum == 0) {
            return false;
        }
        selfNum--;
        $(_thisTag).find(".slide-sub-box li").eq(selfNum).click();
    });
    $(_thisTag).find(".slide-right").on('click', function () {
        if (selfNum >= selfNums - 1) {
            return false;
        }
        selfNum++;
        $(_thisTag).find(".slide-sub-box li").eq(selfNum).click();
    });
};

tabScroll("#about1");
tabScroll("#about2");
tabScroll("#about3");


$(".jsTab1 li").not($(".notab")).on('click', function () {
    var thisTab = $(this).parents(".jsTab1");
    var tabSub = thisTab.parents(".jsTabs1").find(".u-tab-content").find(".tab-sub");
    var thisIndex = $(this).index();
    thisTab.find("li").removeClass('z-current');
    $(this).addClass('z-current');
    tabSub.addClass('z-visible');
    tabSub.eq(thisIndex).removeClass('z-visible');
});

// function scrollFixed(thisName) {
//     var scrollTime;
//     var titleTop = $(thisName).offset().top;
//     var footTop = $(".m-foot").offset().top;
//     if (scrollTime) {
//         clearTimeout(scrollTime);
//     }
//     scrollTime = setTimeout(function () {
//         if ($(window).scrollTop() > titleTop && $(window).scrollTop() < footTop - 500) {
//             $(thisName).addClass("z-fixed");
//         } else {
//             $(thisName).removeClass("z-fixed");
//         }
//     }, 50);
// }
// $(window).on('scroll', function () {
//     $(".m-tahrir").length && scrollFixed(".m-tahrir");
//     $(".m-about").length && scrollFixed(".m-about");
//     $(".m-side-left").length && scrollFixed(".m-side-left");
//     $(".m-date").length && scrollFixed(".m-date");
// });






























(function ($) {
    "use strict";
    //  弹出层
    $.fn.alertCount = function (options) {
        var defaults = {
            addClass: '',
            alertColse: '<div class="alert-colse"><i class="iconfont icon-close"></i></div>',
            alertContent: '请配置alert_content',
            btnText: ['提交', '关闭'], //按钮文字
            bgIsclick: false,//点击背景是否关闭
            cancel_fun: function () { },//取消后的执行方法
            end_fun: function () { } //触发后的执行方法
        };
        var btns = "";
        options = $.extend({}, defaults, options || {});
        if (options.btnText.length === 0) {
            btns = "";
        } else if (options.btnText.length === 1) {
            btns = '<div class="btns"><div class="submit-btn">' + options.btnText[0] + '</div></div>';
        } else {
            btns = '<div class="btns"><div class="submit-btn">' + options.btnText[0] + '</div><div class="cancel-btn">' + options.btnText[1] + '</div></div>';
        }
        var alertHtml = '<div class="m-alert ' + options.addClass + '"><div class="alert-bg"></div><div class="alert-text"><div class="alert-text-content">' + options.alertColse + options.alertContent + btns + '</div></div></div>';
        $(alertHtml).appendTo('body').show().addClass("z-show");
        $(".submit-btn").on('click', function () {
            var isClose = options.end_fun();
            if (isClose) {
                $(".m-alert").removeClass("z-show");
                setTimeout(function () {
                    $(".m-alert").remove();
                }, 200);
            }
        });
        $(".cancel-btn").on('click', function () {
            $(".m-alert").removeClass("z-show");
            setTimeout(function () {
                $(".m-alert").remove();
            }, 200);
            options.cancel_fun();
        });
        if (options.bgIsclick) {
            $('.alert-bg').on('click', function () {
                $(".m-alert").removeClass("z-show");
                setTimeout(function () {
                    $(".m-alert").remove();
                }, 200);
                options.cancel_fun();
            });
        }
        $('.alert-colse').on('click', function () {
            $(".m-alert").removeClass("z-show");
            setTimeout(function () {
                $(".m-alert").remove();
            }, 200);
            options.cancel_fun();
        });
    };
})(jQuery);


var tapViewMore = (function ($) {
    "use strict";
    //加载更多
    function tapViewMore(view_html, view_class, z_num) {
        var is_tap = true;
        var tap_num = 0;
        $(view_class).find(".view_btn").on('click', function () {
            var _this = $(this);
            if (is_tap) {
                is_tap = false;
                if (tap_num < z_num) {
                    tap_num++;
                    _this.addClass("hidden");
                    $(view_class).find(".m_loaded").removeClass("hidden");
                    setTimeout(function () {
                        $(view_class).find("ul").append(view_html);
                        $(view_class).parent(".lesson-cont").css({
                            height: $(view_class).outerHeight()
                        });
                        $(view_class).find(".m_loaded").addClass("hidden");
                        $(view_class).find(".view_btn").removeClass("hidden");
                        is_tap = true;
                        if (tap_num >= z_num) {
                            $(view_class).find(".view_btn").addClass("hidden");
                            $(view_class).find(".m_loaded").removeClass("hidden").html("没有更多了！");
                            is_tap = false;
                        }
                    }, 1000);
                } else {
                    $(view_class).find(".view_btn").addClass("hidden");
                    $(view_class).find(".m_loaded").removeClass("hidden").html("没有更多了！");
                }
            }
        });
    };
    return tapViewMore;
})(jQuery);